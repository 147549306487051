<template>
  <BModal
    id="modal-rules"
    ref="modal-rules"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="lg"
    :centered="true"
  >
    <div class="text-black text-xl font-medium mb-2">
      Petunjuk Verifikasi KTP
    </div>
    <BRow class="justify-center">
      <BCol
        v-for="rule in rules"
        :key="rule.id"
        lg="6"
      >
        <BCard :body-class="rule.bodyClass">
          <div class="flex flex-row justify-around">
            <BImg
              v-for="image in rule.image"
              :key="image.id"
              :width="image.width"
              :height="image.height"
              :src="image.url"
              class="object-contain"
            />
          </div>
          <BRow class="justify-center items-center mt-2">
            <BIcon
              :icon="rule.icon"
              :variant="rule.variant"
            />
            <span :class="rule.classStatus">{{ rule.status ? 'Benar' : 'Salah' }}</span>
          </BRow>
          <div :class="rule.classTitle">
            {{ rule.title }}
          </div>
          <li
            v-for="main in rule.main_rules"
            :key="main.id"
            :class="rule.classLabel"
          >
            {{ main.label }}
          </li>
        </BCard>
      </BCol>
      <BButton
        variant="primary"
        @click="$bvModal.hide('modal-rules')"
      >
        Mengerti
      </BButton>
    </BRow>
  </BModal>
</template>

<script>
import { rules } from '../config'

export default {
  data() {
    return {
      rules,
    }
  },
}
</script>
